<template>
  <CRow class>
    <CCol col="12" md="6">
      <EditUserFormComponent />
    </CCol>
  </CRow>
</template>

<script>
import EditUserFormComponent from "../../../../components/UserAccount/EditUserFormComponent";
import Collapses from "../../../base/Collapses";
import { mapActions } from "vuex";
import endpoints from "@/constants/endpoints";
export default {
  data() {
    return {
      user: null
    };
  },
  name: "EditUser",
  components: { Collapses, EditUserFormComponent },
  methods: {
    ...mapActions(["getAction"])
  },
  async mounted() {
    const userId = this.$route.params.userId;
    if (userId) {
      const isNumber = new RegExp("^[0-9]+$"); // check number
      if (
        isNumber.test(userId) &&
        this.$store.state.user.userSelected.id == userId
      ) {
        this.getAction("edit");
      }
    }
  },
  props: {
    userId: {
      type: String,
      default: undefined
    }
  }
};
</script>

<style scoped>
</style>
