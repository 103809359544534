<template>
  <div id="editUserForm" class="editUserForm__wrap">
    <CCard>
      <CCardBody v-on:change="isFormChange=true">
        <!-- <CRow v-if="isEdit" class="mb-3">
          <CCol class="d-flex justify-content-end status-wrap">
            <CButton
              variant="outline"
              color="primary"
              :class="(action == 'UNACTIVATED')?'btn-selected':'btn-custom-link'"
              @click="selectAction('UNACTIVATED')"
            >Deactivate</CButton>
            <CButton
              variant="outline"
              color="primary"
              :class="(action == 'LOCKED')?'btn-selected':'btn-custom-link'"
              @click="selectAction('LOCKED')"
            >Lock</CButton>
            <CButton
              variant="outline"
              color="primary"
              :class="(action == 'ACTIVATED')?'btn-selected':'btn-custom-link'"
              @click="selectAction('ACTIVATED')"
            >Activate</CButton>
          </CCol>
        </CRow>-->
        <ValidationObserver ref="observer" @submit.prevent="userAction">
          <ValidationProvider :name="$t('EDIT_USER_FORM_COMPONENT_FIELD_NAME_VALIDATE_ROLE')" rules="required" v-slot="{ errors }">
            <div class="select-wrap form-group form-select">
              <label>{{$t("EDIT_USER_FORM_COMPONENT_LABEL_ROLE")}}</label>
              <select class="form-control role-select" v-model="role">
                <option
                  v-for="option in options"
                  v-bind:value="option.id"
                  :key="option.id"
                >{{ option.name }}</option>
              </select>
            <CRow class="row-err">
                <CCol class="error-msg-wrap">
                  <p class="error-msg">{{errors[0]}}</p>
                </CCol>
              </CRow>
            </div>
          </ValidationProvider>

        <template>
          <ValidationProvider :name="$t('EDIT_USER_FORM_COMPONENT_FIELD_NAME_VALIDATE_NAME')" rules="required">
            <div  slot-scope="{ errors }">
              <CInput 
                :label="$t('EDIT_USER_FORM_COMPONENT_LABEL_NAME')"
                :placeholder="isCreate ? $t('EDIT_USER_FORM_COMPONENT_PLACEHOLDER_NAME') : ''"
                v-model="name"
                class="form-field-name"
              />
              <CRow class="row-err">
                <CCol class="error-msg-wrap">
                  <p class="error-msg">{{errors[0]}}</p>
                </CCol>
              </CRow>
            </div>
          </ValidationProvider>
        </template>

        <template v-if="isEdit">
          <CRow class="form-edit-email">
            <label>{{$t("EDIT_USER_FORM_COMPONENT_LABEL_EMAIL")}}</label>
            <div class="edit-email-name">
              <h6>
                <strong>{{user.email}}</strong>
              </h6>
            </div>
          </CRow>
          <CRow class="form-edit-email" v-if="this.hasActive === true">
            <label>{{$t("EDIT_USER_FORM_COMPONENT_LABEL_STATUS")}}</label>
            <div class="edit-email-name">
              <label class="btn-switch">
                <input type="checkbox" class="checkbox" v-model="checkStatus">
                <span class="check-silder"></span>
              </label>
            </div>
          </CRow>
        </template>
          <ValidationProvider v-if="isCreate" :name="$t('EDIT_USER_FORM_COMPONENT_FIELD_NAME_VALIDATE_EMAIL')" rules="required|email">
            <div slot-scope="{ errors }">
              <CInput
                :label="$t('EDIT_USER_FORM_COMPONENT_LABEL_EMAIL')"
                :placeholder="$t('EDIT_USER_FORM_COMPONENT_PLACEHOLDER_EMAIL')"
                horizontal
                v-model="email"
                class="form-group--custom"
              />
              <CRow class="row-err">
                <!-- <CCol :sm="{offset:'3'}"></CCol> -->
                <CCol class="error-msg-wrap">
                  <p class="error-msg">{{errors[0]}}</p>
                </CCol>
              </CRow>
            </div>
          </ValidationProvider>
        </ValidationObserver>
        <CRow class="btn-save float-right">
          <button
            v-if="isEdit"
            @click="reSendActiveCode(user.id)"
            :class="user.user_sts=='ACTIVATED'?'mr-2 text-white btn btn-info disabled':'mr-2 text-white btn btn-info'"
            :disabled="user.user_sts=='UNACTIVATED'?false:true"
          >{{$t("EDIT_USER_FORM_COMPONENT_BUTTON_RESEND_ACTIVE_CODE")}}</button>
          <CButton v-if="isCreate" @click="openWarningLeaveModal" class="btn-save--cancel">{{$t("EDIT_USER_FORM_COMPONENT_BUTTON_CANCEL")}}</CButton>
          <CButton
            @click="userAction(user.id,role,email,name)"
            color="primary"
            class="btn-save--create"
          >{{isCreate ? $t("EDIT_USER_FORM_COMPONENT_BUTTON_CREATE") : $t("EDIT_USER_FORM_COMPONENT_BUTTON_SAVE")}}</CButton>
        </CRow>
      </CCardBody>
    </CCard>  
    <CModal id="leavePageModal" :show.sync="warningLeavePage" :centered="true">
      <div class="">
        <div class="tit-leave">
          <h4>{{$t("EDIT_USER_FORM_COMPONENT_TITLE_MODAL_UNSAVED_CHANGES")}}</h4>
        </div>
        <p class="text-modal">{{$t("EDIT_USER_FORM_COMPONENT_CONTENT_MODAL_UNSAVED_CHANGES")}}</p>
        <p class="text-modal">{{$t("EDIT_USER_FORM_COMPONENT_QUESTION_MODAL_UNSAVED_CHANGES")}}</p>
      </div>

      <template #footer>
        <CButton
          class="stay-btn"
          @click="warningLeavePage = false"
          color="dark"
          variant="outline"
        >{{$t("EDIT_USER_FORM_COMPONENT_BUTTON_STAY_ON_THIS_PAGE")}}</CButton>
        <router-link v-if="isCreate" class="leave-btn" to="/admin/user-management">{{$t('EDIT_USER_FORM_COMPONENT_BUTTON_LEAVE_THIS_PAGE')}}</router-link>
      </template>
    </CModal>
  </div>
</template>

<script>
import endpoints from "@/constants/endpoints";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      status: "",
      email: "",
      name: "",
      checkStatus: false,
      isFormChange: false,
      warningLeavePage: false,
      options: [],
      role: '',
      isEdit: false,
      isCreate: false,
      hasActive: false
    };
  },
  computed: mapState({
    user: state => state.user.userSelected,
    action: state => state.user.action,
    userPermission: state => state.auth.userPermission,
  }),

  async mounted() {
    await this.getRoles();
    if (this.action == "edit") {
      this.isEdit = true;
      let role = this.options.filter(
        option => option.name === this.user.role[0]
      )[0];
      this.role = role.id;
      this.status = this.user.user_sts;
    } else {
      this.isCreate=true;
    }
    this.checkStatus = await this.currentStatusUser();
    // console.log(this.options, 'role as');
    if(!this.isCreate){
      this.name = this.user.name;
    }
    await this.checkPermission();
  },
  methods: {
    ...mapActions([
      "getSelectedUser",
      "updateUser",
      "createUser",
      "reSendActiveCode"
    ]),
    currentStatusUser(){
      let currentSts = this.user.user_sts;
      if(currentSts==='ACTIVATED'){
        return true;
      }
      else{
        return false;
      }
    },
    changeStatusUser(){
      if(this.checkStatus){
        this.user.user_sts = "ACTIVATED"
      }else{
        this.user.user_sts = "UNACTIVATED"
      }
      console.log(this.user.user_sts);
    },
    async getRoles() {
      let data = await this.$http.get(endpoints.getRoleHasPermission);
      if (data) {
        let result = [...data.data];
        console.log("data", data);
        this.options = result;
      }
    },
    async userAction(id, role, email, name) {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      if (this.isCreate) {
        this.create(email, name, role);
      } else {
        this.changeStatusUser();
        if (this.isEdit && name.length>0) {
          this.edit(id, name, role, this.user.user_sts);
        }else{
          this.edit(id, this.user.name, role, this.user.user_sts);
        }
      }
    },
    async edit(id, name, role, status) {
      let updateUser = {
        id: id,
        name: name,
        role_id: role,
        status: status
      };
      await this.updateUser(updateUser);
    },
    async create(email, name, role) {
      let newUser = {
        email: email,
        name: name,
        role_id: role
      };
      await this.createUser(newUser);
    },
    openWarningLeaveModal(){
      if(this.isFormChange){
        this.warningLeavePage=true;
      }else{
        this.$router.push('/admin/user-management');
      }
    },
    reName(e){
      this.name = e;
    },
    checkPermission(){
      if(this.userPermission.length > 0){
        this.userPermission.forEach(items =>{
          if(items.name === 'ACTIVE_USER'){
            this.hasActive = true
          }
        })
      }
     }
  },
  name: "EditUserFormComponent"
};
</script>

<style lang="scss">
#editUserForm {
  .custom-header {
    background: #0f85ea;
    padding-top: 5px;
    padding-bottom: 0;
    color: #fff;
  }
  .switch {
    padding-left: 10px;
    @media (max-width: 1100px) {
      margin-top: 10px;
    }
  }
  .btn-save {
    margin-right: 0;
  }
  .btn-custom-link {
    outline: none;
    min-width: 120px;
  }
  .btn-selected {
    outline: none;
    min-width: 120px;
    background: #4267db;
    color: #eeeeee;
  }
  .status-wrap {
    button {
      margin-left: 20px;
    }
  }

  .email-wrap {
    display: block;
  }
  .error-msg {
    color: red;
  }
  .disabled {
    cursor: not-allowed;
  }
  .role-select {
    width: 100%;
    option {
      width: 100%;
    }
  }
  .card-body{
    label{
      font-weight: 500;
    }
    input{
      &::placeholder{
        font-weight: 300;
      }
    }
  }
  @media screen and (min-width: 576px) {
    .select-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      select {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
  }
}
.editUserForm__wrap{
  padding-left: 9px;
  @media only screen and ( max-width: 1024px ){
    padding: 0 15px;  
  }
  .card-body{
    padding: 32px 15px;
    label{
      line-height: 27px;
      padding: 0 10px 0 0;
      text-align: right;
      width: 60px;
    }
    .select-wrap{
      justify-content: center !important;
      @media only screen and ( max-width: 1024px ){
        display: flex;
        justify-content: flex-start !important;
      }
      .form-control{
        flex: 0 0 60% !important;
        width: 60% !important;
        max-width: 60% !important;
        @media only screen and ( max-width: 1024px ){
          flex: unset !important;
          max-width: unset !important;
          width: calc( 100% - 60px ) !important;
        }
      }
    }
    .form-select{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 5px;
        margin-top: 1rem;
        label{
            width: 60px;
            text-align: right;
            padding-right: 15px;
            line-height: 25px;
            color: #3E4B65;
            // font-family: "SF UI Display";
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.5px;
        }
        .form-control{
            width: 55%;
            @media only screnn and ( max-width: 1024px ){
                width: 75%;
            }
        }
        .row{
          width: 100%;
        }
    }
    .form-group--custom{
      justify-content: center;
      margin: 0 0 5px 0;
      @media only screen and ( max-width: 1024px ){
        justify-content: flex-start; 
      }
      .col-sm-3{
        flex: unset;
      }
      .col-sm-9{
        flex: 0 0 60%;
        max-width: 60%;
        padding: 0;
        @media only screen and ( max-width: 1024px ){
          max-width: unset;
          flex: unset;
          width: calc( 100% - 60px );
        }
      }
    }
    .form-field-name{
      display: flex;
      flex: 0 0 100%;
      justify-content: center;
      margin-bottom: 5px;
      .form-control{
        width: 60%;
        @media only screen and ( max-width: 1024px ){
          width: calc( 100% - 60px );
        }
      }
    }
    .form-edit-email{
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      margin: 0;
      justify-content: center;
      margin-bottom: 1rem;
      @media only screen and ( max-width: 990px ){
        justify-content: flex-start;
      }
      label{
        width: 60px;
        padding: 0 10px 0 0;
        margin: 0;
      }
      .edit-email-name{
        width: 60%;
        padding: 0;
        margin: 0;
        h6{
          margin: 0;
          line-height: 27px;
        }
        .btn-switch{
          position: relative;
          display: inline-block;
          width: 40px;
          height: 24px;
          .checkbox{
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
            &:checked + .check-silder::before{
              border-color: #009900;
              transform: translateX(16px);
            }
            &:checked + .check-silder{
              background: #009900;
            }
          }
          .check-silder{
            position: absolute;
            cursor: pointer;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: #fff;
            border: 1px solid #dadada;
            transition: .4s;
            border-radius: 24px;
            &:before{
              position: absolute;
              content: '';
              height: 20px;
              width: 20px;
              left: 1px;
              bottom: 1px;
              top: 1px;
              background: #fff;
              border: 1px solid #dadada;
              transition: .4s;
              border-radius: 50%;
            }
          }
        }
      }

    }
    .error-msg{
      width: calc( 60% + 60px );
      margin: 0 0 15px 0;
      padding-left: 60px;
      text-align: left;
      @media only screen and ( max-width: 1024px ){
        width: 100%;
      }
    }
    .btn-save{
      padding-right: 50px;
      @media only screen and ( max-width: 1024px ){
        padding-right: 0;
      }
      .btn-save--cancel{
        border: none;
        background: unset;
        color: #657187;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 35px;
        margin-right: 50px;
        &:hover,&:visited,&:focus{
          outline: none;
          box-shadow: none;
        }
        @media only screen and ( max-width: 1024px ){
          margin-right: 20px;
        }
      }
      .btn-save--create{
        min-width: 146px;
        border-radius: 4px;
        background-color: #3B4B7E;
        outline: none !important;
      }
    }
  }
}
#leavePageModal{
  .modal-dialog{
    max-width: 380px;
    .modal-header{
      display: none;
    }
    .modal-body{
      padding: 24px 20px 0 20px;
      .tit-leave{
        margin-bottom: 20px;
        h4{
          color: #4A4A4A;
          /* font-family: "SF UI Display"; */
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin: 0;
        }
      }
      .text-modal{
        color: #4A4A4A;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
    .modal-footer{
      border: none;
      justify-content: space-between;
      padding: 25px 20px 12px 20px;
      .stay-btn{
        border: 1px solid #CBCBCB;
        border-radius: 5px;
        height: 37px;
        line-height: 37px;
        color: #4A4A4A;
        // font-family: "SF UI Display";
        font-size: 14px;
        line-height: 16px;
        padding: 0 20px;
        &:hover,
        &:visited{
          outline: none;
          background: #fcfcfc;
          color: #4A4A4A;
        }
      }
      .leave-btn{
        height: 37px;
        line-height: 37px;
        padding: 0px 20px;
        border-radius: 4px;
        background-color: #3B4B7E;
        color: #FFFFFF;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}
.row-err{
  margin: 0;
  .error-msg-wrap{
    padding: 0;
    display: flex;
    justify-content: center;
  }
}
</style>
